import React, { useState, useEffect, useMemo } from "react";
import "../index.scss";
import * as Survey from "../Contexts/SurveyContext";
import Questionnaire from "../Components/Questionnaire";
import FinishPage from "./FinishPage";
import VerifyPage from "./VerifyPage";
import * as Ant from "antd"; //wiki加入IE判斷
import { Color } from "../Components/Widget"; //wiki加入IE判斷
import { ExclamationCircleFilled } from "@ant-design/icons"; //wiki加入IE判斷

// 2022-08-09 ALVIN 增加Slate
import { withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import RichTextEditor from "../Components/RichTextEditor";

import WelcomePage from "./WelcomePage";
import ReactGA from "react-ga4";

const RichTextUtils = require("../Utils/RichTextUtils");

const queryString = require("query-string");
let windowGlobal = typeof window !== "undefined" && window;

/**
 * 問卷填寫頁面
 * @param {*} props
 * @returns
 */
function Page(props) {
  let isTestData = false;
  if (typeof window !== "undefined") {
    // query string 包含是否為測試站台
    isTestData = queryString.parse(window.location.search).test;
  }

  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [surveyStatus, setSurveyStatus] = useState(2);
  const [validRegister, setValidRegister] = useState();
  const [validField, setValidField] = useState(0);

  // 2022-08-09 ALVIN welcome page data
  const [welcomePageData, setWelcomePageData] = useState(null);

  const id = queryString.parse(props.location.search).id; // 問券ID
  // const surveyEnv = 2; //正式環境
  // const provideType = 1;
  const surveyEnv =
    queryString.parse(props.location.search).test === undefined ? 2 : 1; // 正式環境是 undefined 測試環境是1
  const provideType = queryString.parse(props.location.search).ProvideType; //數據來源 1:測試環境;2:正式環境
  const p =
    queryString.parse(props.location.search).p === undefined
      ? ""
      : queryString.parse(props.location.search).p;
  const ifPreview =
    queryString.parse(props.location.search).preview === undefined
      ? false
      : true;
  const ifTemplate =
    queryString.parse(props.location.search).template === undefined
      ? false
      : true;
  const surveyAnswerStatus = queryString.parse(props.location.search).status;

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  //用IE瀏覽器開啟時，跳出的彈跳視窗 wiki
  function _confirmIE() {
    Ant.Modal.warning({
      title: "本問卷不支援 IE 瀏覽器",
      content: "為提供最佳操作體驗，請改用 Chrome 瀏覽器。",
      okText: "關閉",
      onOk: () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  useEffect(() => {
    ReactGA.initialize("G-WNJFKVQLF0");
    ReactGA.send("pageview");
    //判斷是否為IE瀏覽器 wiki
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      setLoading(false);
      _confirmIE();
      return;
    }

    /**
     * 判斷題目暫存
     * @returns
     */
    async function getToken() {
      if (
        !ifPreview &&
        !ifTemplate &&
        surveyAnswerStatus === undefined &&
        provideType === undefined
      ) {
        //didn't have ProvieType 問卷不存在
        setSurveyStatus(1);
      } else {
        try {
          windowGlobal = typeof window !== "undefined" && window;
          windowGlobal.localStorage.setItem("SEnv", surveyEnv);

          if (!ifPreview && !ifTemplate) {
            // 取的 Token 由外層移置 if 之後
            const resp = await Survey.Actions.getExternalUserToken(
              id,
              surveyEnv,
              provideType,
              p
            );

            // 原本為  if (resp && !ifPreview && !ifTemplate)
            // 但因為 fetch token 移至 if 內層
            // 所以 resp 多拆判斷
            if (resp) {
              // hotfix: 如果為需要驗證密碼，則先清空token，不然重新測試會略過驗證頁面造成錯誤
              if (resp.ValidRegister === 2) {
                windowGlobal.localStorage.removeItem("token");
                Survey.Actions.setExternalLocalStorageToken(false);
              }

              if (resp.ValidRegister === 1 || resp.ValidRegister === 3) {
                try {
                  windowGlobal.localStorage.setItem("token", resp.Token);
                  Survey.Actions.setExternalLocalStorageToken(true);
                } catch (err) {
                  if (
                    err.name === "QUOTA_EXCEEDED_ERR" ||
                    err.name === "QuotaExceededError"
                  ) {
                    alert(
                      "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
                    );
                  }
                }
              }
              setValidField(resp.ValidField);
              setSurveyStatus(resp.SurveyStatus);
              setValidRegister(resp.ValidRegister);
              setAccessToken(resp.Token);

              // 2022-08-09 ALVIN 歡迎API
              // 狀態如果是 11 則有歡迎頁面
              if (resp.SurveyStatus === 11) {
                // 顯示歡迎頁
                let externalData = await Survey.Actions.fetchExternalSurvey(
                  id,
                  true
                );
                if (externalData.code === "200") {
                  if (!externalData.welcomePage.WelcomePageStyle) {
                    setLoading(false);
                    return;
                  }
                  console.log("Welcome page returned", externalData);
                  setWelcomePageData(externalData.welcomePage);
                }
              }
            }
          } else {
            setSurveyStatus(1);
          }
        } catch (err) {
          throw err;
        }
      }
      setLoading(false);
    }
    getToken();
  }, []);

  const renderPage = props => {
    if (loading) {
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          <Ant.Spin />
        </div>
      );
    }

    // surveyStatus
    // 1 問卷不存在
    // 2 正常問卷
    // 2 填寫登入資料頁面(符合資格才能填寫) validRegister = 2 || undefined 填寫個資
    // 3 本問卷已截止
    // 4 測試問卷已額滿
    // 5 參數傳遞驗證失敗
    // 11 個資同意
    // others 請確定網路連結正確

    let img = "/images/EndPage_Web.png";
    let buttonText = "關閉視窗";

    // 預覽頁面
    if (ifPreview) {
      return (
        <Questionnaire externalLink={false} ifTemplate={false} {...props} />
      );
    }
    // 樣板預覽頁面
    if (ifTemplate) {
      return (
        <Questionnaire externalLink={false} ifTemplate={true} {...props} />
      );
    } else {
      if (surveyStatus === 1) {
        //未開放
        return (
          <FinishPage
            isTestData={isTestData}
            title="問卷不存在"
            topImg="/images/EndPage_Web.png"
            surveyStatus={surveyStatus}
          />
        );
      } else if (
        surveyStatus === 2 &&
        (validRegister === 1 || validRegister === 3)
      ) {
        return (
          <Questionnaire
            externalLink={true}
            ifTemplate={false}
            provideType={provideType}
            passByParameter={p}
            {...props}
          />
        );
      } else if (
        surveyStatus === 2 &&
        (validRegister === 2 || validRegister === undefined)
      ) {
        //填寫登入資料頁面
        return (
          <VerifyPage
            title="填寫登入資料頁面"
            surveyId={id}
            validField={validField}
            provideType={provideType}
            accessToken={accessToken}
            {...props}
          />
        );
      } else if (surveyStatus === 3) {
        //已截止 已額滿 測試問卷已截止
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="本問卷已截止"
            surveyStatus={surveyStatus}
          />
        );
      } else if (surveyStatus === 4 || surveyStatus === 6) {
        //已截止 已額滿 測試問卷已額滿
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="本問卷已額滿"
          />
        );
      } else if (surveyStatus === 5) {
        //參數傳遞驗證失敗
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="發生錯誤, 請重新使用連結開啟"
          />
        );
      } else if (surveyStatus === 11) {
        console.log("props", props);
        // 進行個資同意
        let text;

        const initialWelcomePage = {
          WelcomePageStyle:
            '[{"type":"align-left","children":[{"type":"paragraph","children":[{"text":"您好！感謝您參與此問卷調查，為瞭解客戶意見以提供更好服務，誠摯邀請您參加線上調查。您所填寫的資料我們僅會用於整體統計分析，不會揭露個人資訊，敬請安心填答。","size":"20","bold":true,"color":"#324250"}]}]}]',
          WelcomePagePic: "/images/EndPage_Web.png",
          ButtonSentence: "前往問卷",
        };

        if (props.hasOwnProperty("WelcomePageStyle")) {
          text = welcomePageData?.WelcomePageStyle;
          text = RichTextUtils.catText(text);
          img = welcomePageData?.WelcomePagePic;
          buttonText = welcomePageData?.ButtonSentence;
        } else {
          text = initialWelcomePage.WelcomePageStyle;
          img = initialWelcomePage.WelcomerPagePic;
          buttonText = initialWelcomePage.ButtonSentence;
        }

        return (
          <WelcomePage
            surveyId={id}
            afterSubmit={() => setSurveyStatus(2)}
            welcomePageData={welcomePageData}
          >
            {welcomePageData?.WelcomePageStyle ? (
              <Slate
                editor={editor}
                value={JSON.parse(welcomePageData.WelcomePageStyle)}
              >
                <RichTextEditor readOnly={true} />
              </Slate>
            ) : (
              <Slate editor={editor} value={JSON.parse(text)}>
                <RichTextEditor readOnly={true} />
              </Slate>
            )}
          </WelcomePage>
        );
      } else {
        Survey.Actions.setExternalLocalStorageToken(false);
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="請確定網路連結正確"
          />
        );
      }
    }
  };

  return <Survey.Provider>{renderPage(props)}</Survey.Provider>;
}

export default Page;
