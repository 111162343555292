import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Color, FontSize } from "../Components/Widget";
// // import ElementInput from "../Components/ElementInput"; // https://hackmd.io/@2AjnHnG1RwiubyIHKh-iTw/S1PsWyoBv
import * as Ant from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import * as Survey from "../Contexts/SurveyContext";
import Questionnaire from "../Components/Questionnaire";
import AlertWindow from "../Components/AlertWindow";
import { navigate } from "gatsby";

import chtLogo from "../images/CHTLogo_color.svg";

/**
 * 資料驗證頁面 當surveyStatus=2 && validRegister=2 時進入
 * @param {title} text 標題
 * @param {surveyId} uuid 問卷ID
 * @param {validField} text 驗證欄位 Code
 * @param {provideType} int 1 = 測試 2 = 正式
 * @param {validRegister} int 是否需驗證, 1 = 匿名 2 = 驗證 3 = 參數
 * @param {accessToken} string 驗證 Token
 * @return {VerifyPage}
 */
function VerifyPage({
  title,
  surveyId,
  validField,
  provideType,
  accessToken,
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [isLogin, setLoginStatus] = useState(false);
  const [sub] = useState("問卷資格驗證，須符合資格才能填寫問卷");
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [isRequired] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [suffix, setSuffix] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: "問卷資格不符合",
    text: "您輸入的資料並不在問卷名單中，請嘗試重新輸入",
    btnText: "確定",
  });

  // 需要驗證的欄位code 不同code帶出不同欄位
  const [validFieldCode, setValidFieldCode] = useState(validField);
  let windowGlobal = typeof window !== "undefined" && window;

  // 頁面初始如果驗證是否有 token
  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    let token = windowGlobal.localStorage.getItem("token");
    if (token) {
      setLoginStatus(true);
      setLoading(false);
      Survey.Actions.setExternalLocalStorageToken(true);
    }
  }, []);

  useEffect(() => {
    setValidFieldCode(validField);
    switch (validField) {
      case 101:
        setLabel("CHT 會員帳號");
        setPlaceholder("請輸入您的會員編號，如12345678");
        break;
      case 102:
        setLabel("中華電信 HiNet 客戶編號");
        setPlaceholder("請輸入 HN 後面的數字，例12345678");
        break;
      case 103:
        setLabel("中華電信 MOD 號碼");
        setPlaceholder("請輸入 MD 後面的數字，例12345678");
        break;
      case 104:
        setLabel("市話號碼");
        setPlaceholder("區碼+市話號碼");
        break;
      case 105:
        setLabel("電話號碼");
        setPlaceholder("請輸入市話號碼或手機號碼");
        break;
      case 106:
        setLabel("手機號碼");
        setPlaceholder("0911234567");
        break;
      case 107:
      case 108:
        setLabel("Email");
        setPlaceholder("surevey@cht.com");
        break;
      case 109:
        setLabel("Facebook使用者識別碼");
        setPlaceholder("請輸入");
        break;
      case 110:
        setLabel("序號");
        setPlaceholder("請輸入");
        break;
      case 111:
        setLabel("設備號碼");
        setPlaceholder("12345678");
        break;
      case 202:
      case 203:
      default:
        setLabel("資料");
        setPlaceholder("請輸入");
        break;
    }
    setLoading(false);
  }, [validField]);

  /**
   * 點擊送出後觸發事件
   */
  const clickOnSubmit = async () => {
    //ValidField 驗證欄位
    //101:CHT會員編號
    //102:HN號碼
    //103:MD號碼
    //104:市話號碼
    //105:電話號碼
    //106:手機號碼
    //107:主要聯絡信箱
    //108:次要聯絡信箱
    //109:Facebook使用者識別馬
    //110:序號
    //111:設備號碼
    //202:備用欄位1
    //203:備用欄位2
    setErrMsg("");
    const mobileRule = /^09[0-9]{8}$/;
    const isNumber = /^[0-9]+$/;
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    const isEnOrNumber = /^[A-Za-z0-9]+$/;
    let submit = true;
    switch (validFieldCode) {
      case 101:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        }
        break;
      case 102:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(isNumber) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 103:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(isNumber) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 104:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(isNumber) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 105:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(isNumber) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 106:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(mobileRule) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 107:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(emailRule) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 108:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(emailRule) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 109:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        }
        break;
      case 110:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        }
        break;
      case 111:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        } else if (inputValue.search(isEnOrNumber) === -1) {
          setSuffix(true);
          setErrMsg("格式錯誤");
          submit = false;
        }
        break;
      case 202:
      case 203:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        }
        break;
      default:
        if (!inputValue) {
          setSuffix(true);
          setErrMsg("不能為空");
          submit = false;
        }
    }
    // 送出驗證，若成功轉問卷頁面
    if (submit) {
      setLoading(true);
      const resp = await Survey.Actions.externalLoginVerify(
        surveyId,
        provideType,
        validField,
        inputValue,
        accessToken
      );
      if (resp.code === "200") {
        //轉問券
        windowGlobal = typeof window !== "undefined" && window;
        try {
          windowGlobal.localStorage.setItem("token", accessToken);
          windowGlobal.localStorage.setItem("verifyInfo", inputValue);

          const privacyJSON = localStorage.getItem("privacy");
          await Survey.Actions.submitPrivacy(privacyJSON, false);
          setLoading(false);
          setLoginStatus(true);
        } catch (err) {
          if (
            err.name === "QUOTA_EXCEEDED_ERR" ||
            err.name === "QuotaExceededError"
          ) {
            alert(
              "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
            );
          }
        }

        Survey.Actions.setExternalUserLogin(true);
        setLoginStatus(Survey.Actions.shouldRenderExternalSurvey());
        Survey.Actions.setExternalLocalStorageToken(true);
      } else if (resp.code && resp.code === "406") {
        //token invalid
        setAlertMessage({
          title: "訊息",
          text: "連線逾時，重新登入",
          btnText: "確定",
        });
        setIsAlert(true);
      } else {
        //重填
        setAlertMessage({
          title: "問卷資格不符合",
          text: "您輸入的資料並不在問卷名單中，請嘗試重新輸入",
          btnText: "確定",
        });
        setIsAlert(true);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }
  return isLogin ? (
    <Survey.Provider>
      <Questionnaire externalLink={true} provideType={provideType} {...props} />
    </Survey.Provider>
  ) : (
    <Wrapper>
      <Main>
        <Header />
        <div className="wrapper">
          <div className="title">{title}</div>
          <div className="sub">{sub}</div>
          <div className="content">
            <div className="label">
              <span className={isRequired ? "asterisk d-inline" : "asterisk"}>
                *
              </span>
              <span className="text">{label}</span>
            </div>
            <DefaultInput
              type="blue"
              width="365px"
              moblieWidth="295px"
              height="40px"
            >
              <Ant.Input
                placeholder={placeholder}
                value={inputValue}
                type="text"
                className={
                  suffix ? "err default-input-wrapper" : "default-input-wrapper"
                }
                onChange={e => setInputValue(e.target.value)}
                suffix={
                  <Ant.Tooltip>
                    <InfoCircleFilled
                      className={suffix ? "icon" : "d-none"}
                      size={20}
                    />
                  </Ant.Tooltip>
                }
              />
              <p className="errMsg">{suffix && errMsg}</p>
            </DefaultInput>
          </div>
          <div className="btnWrapper">
            <Ant.Button
              type="primary"
              className="btn btn-lightBlue"
              onClick={() => navigate("http://www.cht.com.tw")}
            >
              取消
            </Ant.Button>
            <Ant.Button
              type="primary"
              className="btn btn-blue"
              onClick={() => clickOnSubmit()}
            >
              送出
            </Ant.Button>
          </div>
        </div>
      </Main>
      <Footer>
        <img src={chtLogo} alt="" />
      </Footer>
      {isAlert && ( //TODO: Hanford needs to write onClick function in AlertWindow
        <AlertWindow
          title={alertMessage.title}
          text={alertMessage.text}
          btnText={alertMessage.btnText}
          setAlertBtnAction={setIsAlert}
          isOpen={isAlert}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .d-inline {
    display: inline-block !important;
  }
  .d-none {
    display: none !important;
  }
  .d-hidden {
    visibility: hidden !important;
  }
`;

const Header = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  height: 6px;
  background-image: linear-gradient(
    to right,
    #209cff 0%,
    #0267b9 20%,
    #fcaf15 39%,
    #ff874d 60%,
    #96d34f 81%,
    #47d382 100%
  );
`;

const Main = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 40px;
  @media screen and (max-width: 823px) {
    margin-top: 0;
    border-radius: 0;
  }
  .wrapper {
    padding: 0 36px;
    @media screen and (max-width: 823px) {
      min-height: 532px;
      padding: 0 20px;
    }
    .title {
      font-size: ${FontSize.largeTitle}px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33em;
      letter-spacing: 0.48px;
      text-align: left;
      color: ${Color.ChtBlue_8};
      margin: 24px 0 32px;
      @media screen and (max-width: 823px) {
        margin: 16px 0;
        letter-spacing: 0.32px;
        line-height: 32px;
      }
    }
    .sub {
      font-size: ${FontSize.title}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 30px;
      letter-spacing: 0.48px;
      color: ${Color.ChtBlack_8};
      margin-bottom: 24px;
      @media screen and (max-width: 823px) {
        margin-bottom: 32px;
      }
    }
    .content {
      @media screen and (max-width: 823px) {
        padding: 0 16px;
      }
      .label {
        position: relative;
        .asterisk {
          position: absolute;
          display: none;
          width: 8px;
          height: 22px;
          color: #f5222d;
          margin-left: -11px;
          top: 0;
          left: 0;
        }
        .text {
          font-size: ${FontSize.subTitle}px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 28px;
          letter-spacing: 0.48px;
          color: #324250;
        }
      }
    }
    .btnWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 72px;
      @media screen and (max-width: 823px) {
        justify-content: center;
        padding-bottom: 224px;
      }
      .btn {
        width: 112px;
        height: 40px;
        box-shadow: 1px 1px 0.5px 0 rgba(0, 20, 37, 0.1);
        font-size: ${FontSize.head}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5rem;
        letter-spacing: 2px;
        text-align: center;
        @media screen and (max-width: 823px) {
          width: 98px;
          height: 40px;
          border-radius: 0px;
        }
        &:first-child {
          margin-right: 16px;
        }
      }
      .btn-lightBlue {
        border-color: transparent;
        background-color: rgba(109, 190, 255, 0.2);
        color: #209cff;
        &:hover {
          border-color: ${Color.ChtBlue_4};
          background-color: ${Color.ChtBlue_4};
          color: #ffffff;
        }
        &:active {
          border-color: ${Color.ChtBlue_8};
          background-color: ${Color.ChtBlue_8};
          color: #ffffff;
        }
        &:focus {
          /* -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          border: solid 1px ${Color.ChtBlue_5}; */
          border-color: #0691ff;
          background-color: #0691ff;
          color: #ffffff;
          &::after {
            position: absolute;
            width: calc( 100% - 2px );
            height: calc( 100% - 2px );
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            content: '';
            display: block;
            border: solid 1px #f4f6fc;
            background-color: transparent;
          }
        }
        &[disabled] {
          background-color: ${Color.Black_15};
          border-color: transparent;
          color: ${Color.Black_45};
        }
      }
      .btn-blue {
        border-color: ${Color.ChtBlue_5};
        background-color: ${Color.ChtBlue_5};
        color: #ffffff;
        &:hover {
          border-color: ${Color.ChtBlue_4};
          background-color: ${Color.ChtBlue_4};
        }
        &:active {
          border-color: ${Color.ChtBlue_8};
          background-color: ${Color.ChtBlue_8};
        }
        &:focus {
          /* -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
          border: solid 1px ${Color.ChtBlue_5}; */
          border-color: #0691ff;
          background-color: #0691ff;
          &::after {
            position: absolute;
            width: calc( 100% - 2px );
            height: calc( 100% - 2px );
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            content: '';
            display: block;
            border: solid 1px #f4f6fc;
            background-color: transparent;
          }
        }
        &[disabled] {
          background-color: ${Color.Black_15};
          border-color: transparent;
          color: ${Color.Black_45};
        }
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 832px) {
    height: 75px;
    img {
      width: 80px;
    }
  }
`;

const DefaultInput = styled.div`
  margin: 16px 0;
  @media screen and (max-width: 823px) {
    width: ${props => props.moblieWidth};
    margin-bottom: 48px;
  }
  .errMsg {
    height: 20px;
    font-size: ${FontSize.caption}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67em;
    letter-spacing: normal;
    color: ${Color.Red_6};
    margin: 2px 0;
  }
  .default-input-wrapper {
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: unset;
    border-radius: 4px;
    border: solid 1.5px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    font-size: ${FontSize.body}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5em;
    letter-spacing: normal;
    color: ${Color.Black_25};
    @media screen and (max-width: 823px) {
      width: 100%;
    }
    .ant-input-suffix {
      color: ${Color.Red};
    }

    & input.ant-input {
      font-size: ${FontSize.head}px;
      line-height: 1.5em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: ${Color.Black_65};
      &::placeholder {
        font-size: ${FontSize.head}px;
        line-height: 1.5em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: ${Color.Black_25};
      }
    }
  }
  &[type="blue"] {
    .default-input-wrapper {
      &:hover {
        -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
        -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
        box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
        border: solid 1px ${Color.ChtBlue_2};
        color: ${Color.Black_65};
      }
      &-focused,
      &:focus {
        box-shadow: unset;
        border-color: ${Color.ChtBlue_6};
        color: ${Color.Black_65};
        @media screen and (max-width: 823px) {
          -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          border: solid 1px ${Color.ChtBlue_2};
          color: ${Color.Black_65};
        }
      }
      &:filled {
        box-shadow: unset;
        color: ${Color.Black_65};
      }
      &-disabled {
        box-shadow: unset;
        border: solid 1.5px rgba(0, 0, 0, 0.15);
        background-color: rgba(0, 0, 0, 0.1);
        color: ${Color.Black_25};
      }
      &.err {
        box-shadow: unset;
        border-color: ${Color.Red_6};
        color: ${Color.Black_65};
      }
    }
  }
`;

export default VerifyPage;
